/* webfonts Start */
/* @font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Poppins-ExtraBold.woff2') format('woff2'),
    url('./fonts/Poppins-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Poppins-Black.woff2') format('woff2'),
    url('./fonts/Poppins-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Poppins-Bold.woff2') format('woff2'),
    url('./fonts/Poppins-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Poppins-Light.woff2') format('woff2'),
    url('./fonts/Poppins-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Poppins-SemiBold.woff2') format('woff2'),
    url('./fonts/Poppins-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Poppins-Medium.woff2') format('woff2'),
    url('./fonts/Poppins-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Poppins-ExtraLight.woff2') format('woff2'),
    url('./fonts/Poppins-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Poppins-Regular.woff2') format('woff2'),
    url('./fonts/Poppins-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Poppins-Thin.woff2') format('woff2'),
    url('./fonts/Poppins-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
} */
 @font-face {
   font-family: 'Gilroy';
   src: url('./fonts/Gilroy-Bold.woff2') format('woff2'),
     url('./fonts/Gilroy-Bold.woff') format('woff');
   font-weight: 700;
   font-style: normal;
   font-display: swap;
 }

 @font-face {
   font-family: 'Gilroy';
   src: url('./fonts/Gilroy-ExtraBold.woff2') format('woff2'),
     url('./fonts/Gilroy-ExtraBold.woff') format('woff');
   font-weight: bold;
   font-style: normal;
   font-display: swap;
 }

 @font-face {
   font-family: 'Gilroy';
   src: url('./fonts/Gilroy-Black.woff2') format('woff2'),
     url('./fonts/Gilroy-Black.woff') format('woff');
   font-weight: 900;
   font-style: normal;
   font-display: swap;
 }

 @font-face {
   font-family: 'Gilroy';
   src: url('./fonts/Gilroy-Medium.woff2') format('woff2'),
     url('./fonts/Gilroy-Medium.woff') format('woff');
   font-weight: 500;
   font-style: normal;
   font-display: swap;
 }

 @font-face {
   font-family: 'Gilroy';
   src: url('./fonts/Gilroy-Heavy.woff2') format('woff2'),
     url('./fonts/Gilroy-Heavy.woff') format('woff');
   font-weight: 900;
   font-style: normal;
   font-display: swap;
 }

 @font-face {
   font-family: 'Gilroy';
   src: url('./fonts/Gilroy-SemiBold.woff2') format('woff2'),
     url('./fonts/Gilroy-SemiBold.woff') format('woff');
   font-weight: 600;
   font-style: normal;
   font-display: swap;
 }

 @font-face {
   font-family: 'Gilroy';
   src: url('./fonts/Gilroy-Light.woff2') format('woff2'),
     url('./fonts/Gilroy-Light.woff') format('woff');
   font-weight: 300;
   font-style: normal;
   font-display: swap;
 }

 @font-face {
   font-family: 'Gilroy';
   src: url('./fonts/Gilroy-Regular.woff2') format('woff2'),
     url('./fonts/Gilroy-Regular.woff') format('woff');
   font-weight: normal;
   font-style: normal;
   font-display: swap;
 }

 @font-face {
   font-family: 'Gilroy';
   src: url('./fonts/Gilroy-Thin.woff2') format('woff2'),
     url('./fonts/Gilroy-Thin.woff') format('woff');
   font-weight: 100;
   font-style: normal;
   font-display: swap;
 }

 @font-face {
   font-family: 'Gilroy';
   src: url('./fonts/Gilroy-UltraLight.woff2') format('woff2'),
     url('./fonts/Gilroy-UltraLight.woff') format('woff');
   font-weight: 200;
   font-style: normal;
   font-display: swap;
 }

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy-Regular.woff2') format('woff2'),
    url('./fonts/Gilroy-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Gilroy';
}

.login-page {
  font-family: 'Gilroy';
  color: #2D3748;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.button {
  transition: all 0.5s;
  justify-content: center;
  display: inline-flex;
  text-align: center;
  align-items: center;
  gap: 5px;
  min-height: 45px;
  border-radius: 0;
  padding: 9px 22px;
  transition: all 0.5s;
  font-weight: 400;
  border-radius: 12px;
  border: 1px solid;
  transition: all 0.3s ease;
  align-items: center;
  border-style: solid;
}

.login-primary-btn {
  border: 1px solid;
  border-color: #47E385;
  color: #fff;
  background: #47E385;
}

.full-btn {
  width: 100%;
}

.gradient-switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 19px;
}

.gradient-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.gradient-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #4e5757;
  transition: 0.3s;
  border-radius: 34px;
}

.gradient-slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.gradient-switch input:checked+.gradient-slider {
  background: #47e385;
}

.gradient-switch input:checked+.gradient-slider:before {
  transform: translateX(20px);
  left: 0;
}



body {
  margin: 0;
  font-family: 'Gilroy';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,p, span, .ant-btn, .ant-modal-title, label {
  font-family: 'Gilroy';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media print {
  body {
    visibility: hidden;
  }

  #section-to-print {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.modal3-parent>div {
  width: 100% !important;

  height: 100% !important;

  margin-top: 30px;
} 

.modelParent3>div {
  width: auto !important;

  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  padding: 8px 17px !important;

  height: 100% !important;

  border-radius: 8px !important;

}


.modelParent3+div {
  margin-top: 15px;
}

/* ====================== Common CSS ==================== */
.form-control {
  border: 1px solid #E7E7E7;
  /* padding: 13px 18px; */
  padding: 13px 10px;
  font-size: 15px;
  font-weight: 400;
  color: #1C1F23;
  border-radius: 5px;
  appearance: none;
  min-height: 45px;
  max-height: 45px;

  &::placeholder {
    color: #1C1F23;
  }
}

.formfield {
  width: 100%;
  position: relative;
}

select {
  cursor: pointer;
}

.formfield:has(.form-icon) .form-control {
  padding-right: 50px;
}

.form-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.form-group>label {
  font-size: 14px;
  font-weight: 500;
  color: #555555;
  margin: 0 0 5px;
  display: block;
}

.button {
  padding: 12px 25px;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  transition: all 0.3s;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 45px;
}

.primary-btn {
  background-color: #5D5FEF;
  border: 1px solid transparent;

  &:hover {
    border-color: #5D5FEF;
    background-color: #fff;
    color: #5D5FEF;
  }
}

.make_clikcable {
  cursor: pointer;
}
/* update table class with custom hometable className */
.home-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0;
}

.home-table table tr th,
.home-table table tr td {
  text-align: left;
}

.home-table table tr th {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #555555;
  padding: 27px 10px 19px 14px !important;
  background-color: #FFF !important;
  text-transform: capitalize;
  border-top: 1px solid #E0E0E0;
}
.home-table table tr:hover td{
    background-color: #E7E7E7 !important;
}
.home-table table tr td {
  background-color: #FFF;

  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #1C1F23;
  border-top: 1px dashed #BDBDBD;
}

.home-table table tr td p {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
}

table {
  border: 1px solid #E0E0E0;
  border-radius: 10px !important;
  overflow: hidden;
}

/* .home-table table tr th:first-child {
    border-top-left-radius: 10px;
    border-left: 1px solid #E0E0E0;
}

.home-table table tr th:last-child {
    border-top-right-radius: 10px;
    border-right: 1px solid #E0E0E0;
}

.home-table table tr td:first-child {
    border-left: 1px solid #E0E0E0;
}

.home-table table tr td:last-child {
    border-right: 1px solid #E0E0E0;
}

.home-table table tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

.home-table table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}

.home-table table tr:last-child td {
    border-bottom: 1px solid #E0E0E0;
} */

.home-table table tr:hover td {
  background-color: #fff;

}

/* ============== inventory-modal CSS Start ================= */
.modal-card-body {
  padding: 20px 30px;
}

.modal-card {
  max-width: 768px;
  margin: 0 auto;
}



.inventry-edit-img img {
  width: 115px !important;
  height: 101px !important;
  border-radius: 10px;
  max-width: 115px !important;
  max-height: 101px !important;
}

.inventry-edit-img .ant-image {
  width: 100% !important;
}

.inventry-edit-img .ant-image-mask-info {
  text-align: center;
}

.int-img-name {
  display: flex;
  align-items: center;
  gap: 18px;
  margin: 0 0 16px;
  padding-right: 24px;
}
.two-grid-bx:has(.jodi) {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}
.inventry-edit-name p {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  margin: 0 0 11px;
  color: #555555;
}

.inventry-edit-name h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: #1C1F23;
}

.int-edit-item {
  padding: 20px 15px;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  display: flex;
  gap: 32px;
  margin: 0 0 16px;
}

.int-edit-wrap {
  display: flex;
  gap: 25px;
  row-gap: 15px;
  width: 100%;
  justify-items: start;
  justify-content: start;
  flex-wrap: wrap;
}

.int-edit-wrap .int-edit-box:last-child {
  grid-column: span 2;
}

.int-edit-box {
  display: flex;
  align-items: center;
  gap: 5px;
}

.int-edit-box p {
  color: #1C1F23;
  font-weight: 500;
  line-height: 19px;
  font-size: 16px;
}

.int-edit-box h4 {
  color: #1C1F23;
  font-weight: 500;
  line-height: 19px;
  font-size: 16px;
}

.int-edit-item h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}

.int-edit-fields .form-group {
  margin: 0 0 16px;
}
.form-control::placeholder {
  opacity: 0.4;
}
.ant-select-single {
  font-size: 14px;
  height: 45px;
}

/* ============== inventory-modal CSS End ================= */


.bulkedit-print{
  background-color: #fff !important;
  border: 1px solid #5D5FEF !important;
  color: #5D5FEF !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  min-height: 45px !important;
  box-shadow: unset !important;
  min-width: 107px;
  &:hover{
    background-color: #5D5FEF !important;
    color: #fff !important;
  }
  &:disabled{
    opacity: 0.5;
  }
}

.home-table table{
  font-family: 'Gilroy' !important;
}
.ant-input,.ant-select-selection-search-input,.ant-select-selection-item,.ant-collapse-header-text{
  font-family: 'Gilroy' !important;
}
.custom-card,.primary-btn,.light-btn {
  font-family: 'Gilroy' !important;
}



table .ant-checkbox:has(input:checked) .ant-checkbox-inner{
  background-color: #5D5FEF !important;
  border-color: #5D5FEF !important;
}
table .ant-checkbox .ant-checkbox-inner::after{
  background-color: #5D5FEF !important;
}
table tr.ant-table-row-selected td{
  background-color: #5d5fef2b !important;
}
table .ant-checkbox:hover .ant-checkbox-inner{
  border-color: #5D5FEF !important;
}



.ant-select-single {
  width: 100%;
}
span.navbar-name {
  line-height: 1;
  font-weight: 600;
}





.form-group:has(.formfield:empty) {
  display: none;
}
.disabled {
  cursor: not-allowed !important;
}
._addEmpUser{
  display: none;
}
.permisisonCheckbos {
  flex: 0 0 33%;
  margin-bottom: 5px;
}
.permisisonCheckbos .ant-checkbox-wrapper {
  width: auto !important;
  margin-right: 2px !important;
}
.antd-custom-checkbox>span>.ant-checkbox-input {
  transform: scale(3.5);
}

.edit-product .form-group>label {
  margin-bottom: 9px;
  color: #000;
  font-weight: 400;
}
.sku-condition {
  color: #8f8f8f
}
.text-bold{
  font-weight: 600 !important;
}
.error-border>.ant-select>.ant-select-selector {
  border-color: red;
}